//
// overrides.scss
// Dark mode overrides
//

//
// Table of contents
//
// 1. Buttons
// 2. Dropzone
// 3. Quill
//

// Buttons

.btn-white,
.btn-light {
  --#{$prefix}btn-bg: #{$gray-800-dark};
  --#{$prefix}btn-border-color: #{$gray-600-dark};
  --#{$prefix}btn-color: #{$white};

  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled):focus,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus,
  .show > &.dropdown-toggle {
    background-color: $black-dark;
    border-color: $gray-700-dark;
    color: var(--#{$prefix}white);
  }
}