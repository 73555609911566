//
// lift.scss
// Theme utility
//

// this scss comes-up with theme
// .lift {
//   transition: box-shadow .25s ease, transform .25s ease;
// }

// .lift:hover,
// .lift:focus {
//   box-shadow: $box-shadow-lift !important;
//   transform: translate3d(0, -3px, 0);
// }

// .lift-lg:hover,
// .lift-lg:focus {
//   box-shadow: $box-shadow-lift-lg !important;
//   transform: translate3d(0, -5px, 0);
// }

// custom scss for lift
.lift {
  transition: transform .25s ease;
}

.lift:hover,
.lift:focus {
  // box-shadow: $box-shadow-lift !important;
  transform: translate3d(0, -3px, 0);
}

.lift-lg:hover,
.lift-lg:focus {
  // box-shadow: $box-shadow-lift-lg !important;
  transform: translate3d(0, -5px, 0);
}
