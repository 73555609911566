@import "./assets/fonts/feather/feather.css";
@import "./assets/scss/main.scss";
@import "ngx-bootstrap/datepicker/bs-datepicker.css";
@import "./../node_modules/ngx-toastr/toastr.css";

// cursor
.cursor-pointer {
  cursor: pointer;
}

// cursor-not-allowed
.cursor-not-allowed {
  cursor: not-allowed;
}

// Decoration
.text-decoration-underline {
  text-decoration: underline;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #9fb0c7 !important;
}

app-card-create-pipeline,
app-card-trial-plan {
  .card {
    height: 170px;
  }
}

code {
  color: #85d996;
}

pre code {
  color: #85d996;
}

.badge.bg-secondary-soft {
  color: #85aabd;
  letter-spacing: 0.1px;
}

.badge.bg-primary-soft {
  color: #9bc6ff;
  letter-spacing: 0.1px;
}

.badge.bg-success-soft {
  color: #34f9a6;
  letter-spacing: 0.1px;
}

.badge.bg-warning-soft {
  color: #ffd97a;
  letter-spacing: 0.1px;
}

.table {
  --bs-table-color: #ffffff;
  --bs-table-bg: transparent;
  --bs-table-hover-color: #ffffff;
}

.table>tbody {
  border-top: none !important;
}

.form-select {
  background-color: #13253c !important;
  border: 1px solid #13253c !important;
}

// custom style for date picker start
bs-datepicker-container {
  top: 2px !important;
}

.bs-datepicker {
  background: none !important;
  box-shadow: none !important;
}

.bs-datepicker-container {
  padding: 0px;
  outline: none;
}

.bs-datepicker-body {
  background: #1e3a5b !important;
  border: none !important;
}

.theme-blue .bs-datepicker-head {
  background-color: #1e3a5b;
}

.bs-datepicker-body table td {
  color: white;
}

.bs-datepicker-body table td span.is-other-month,
.bs-datepicker-body table td.is-other-month span {
  color: #54708b;
}

.theme-blue .bs-datepicker-body table td.week span {
  color: #9aaec1;
}

//date picker hover
.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #5bc0de;
}

// custom style for date picker end

//auto-fill style for input
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1a314c inset !important;
  -webkit-text-fill-color: #ffffff !important;
}

//flash notification danger link color
.linkDanger {
  color: #ffa3ab;
  text-decoration: none;
}

.linkDanger:hover,
.linkDanger:focus,
.linkDanger:active {
  color: #e6949b;
  text-decoration: none;
}

// model close btn
.btn-close {
  background: transparent;
  border: 0 none !important;
  color: white !important;
  opacity: 1;
  font-size: 26px;
  line-height: 1;
  height: 40px;
  width: 46px;
}

.btn-close:hover,
.btn-close:focus,
.btn-close:active {
  color: rgb(177, 176, 176) !important;
  opacity: 1;
}

// custom disable class for link
.is_disabled {
  cursor: default;
  text-decoration: none;
}

//custom class to disable hyperlink
.disabled-link {
  pointer-events: none;
}

.card-hover-animation:hover {
  transform: translateX(0px) translateY(-11px) !important;
  transition: 0.5s;
}

// check box related css changes
.form-check-input[type="checkbox"] {
  border-radius: 0.3rem;
  outline: 1px solid #9fb0c7;
}

.form-check-input:checked[type="checkbox"] {
  outline: 1px solid #08784aab;
}

.form-check-input:checked {
  background-color: #1d9160;
  border-color: #1d9160;
}

.form-check-input:focus {
  outline: 1px solid #9fb0c7;
}

.checklist .form-check .form-check-label .form-check-input:checked + span {
  text-decoration: line-through;
  color: #9fb0c7;
}

.btn-off-white {
  color: #9fb0c7;
  background-color: #13253c;
  border-color: #244166;
}

.btn-off-white-without-border {
  color: #9fb0c7;
  background-color: #0f1d2e;
  border-color: #0f1d2e;
}

.btn-round-border {
  border-radius: 50%;
}

.list-pagination-prev,
.list-pagination-next {
  .page-item.disabled {
    cursor: not-allowed;

    .page-link {
      color: var(--bs-pagination-disabled-color) !important;
      background-color: var(--bs-pagination-disabled-bg) !important;
    }
  }
}

// popover and tooltip have issue with ngx-bootstrap v9
// fix for ngx-bootstrap v9 for now
// Note: keep this style till ngx-bootstrap fix this
.popover {
  position: absolute;
  top: 0;
  left: 0 #{"/* rtl:ignore */"};
}

.popover-arrow {
  position: absolute;
}

.tooltip {
  position: absolute;
}

.tooltip-arrow {
  position: absolute;
}


// toast notification
.toast-container .ngx-toastr {
  box-shadow: none;
  margin-top: 0.5rem;
  background-size: 18px;
  padding: 15px 15px 10px 50px;
}

.toast-success {
  background-color: #1d9160;
  color: #FFFFFF;
}

.toast-error {
  background-color: #bd2642;
}

.toast-info {
  background-color: #39afd1;
}

.toast-warning {
  background-color: #F6C343;
}

.toast-message {
  a {
    color: #1a314c;
    text-decoration: none;
    font-weight: bold;
  }
  a.light-link {
    border-bottom: 1px dotted;
    border-bottom-color: #1a314c;
  }
  a:hover {
    color: #fff;
    text-decoration: none;
  }
}
// toast notification end

// cron-editor#cron-editor > section.cron-editor-main {
//   background-color: blue !important;
// }

// cron-editor
//   .cron-editor-main
//   .mat-tab-group
//   .mat-tab-header
//   .mat-tab-list
//   .mat-tab-label:focus {
//   border-bottom: 1px solid white !important;
// }

// cron-editor
//   .cron-editor-main
//   .mat-tab-group
//   .mat-tab-header
//   .mat-tab-label-container
//   mat-ink-bar {
//   display: none !important;
// }

// .mat-ripple-element {
//   display: none !important;
// }

// button.mat-tab-header-pagination {
//   color: #fff;
// }

// .cron-form-label {
//   margin-left: 1rem;
//   margin-right: 1rem;
// }

// mat-select {
//   /* Remove Material Design styling classes */
//   /* You might need to inspect your DOM to find the actual classes applied */
//   // all: unset; /* This removes all styles, including Material styles */
//   display: block;
//   width: 100%;
//   padding: 0.5rem 0.75rem;
//   font-size: 0.9375rem;
//   font-weight: 400;
//   line-height: 1.5;
//   color: #ffffff;
//   background-color: #1a314c;
//   background-clip: padding-box;
//   border: 1px solid #1a314c;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   border-radius: 0.375rem;
//   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// }

// // mat-form-field {
// //   margin-left: 2rem;
// //   margin-right: 2rem;
// // }

// // mat-form-field + span {
// //   margin-left: 2rem;
// //   margin-right: 0.5rem;
// // }

// #mat-radio-2 {
//   // margin-bottom: 3rem;
// }

// .mat-form-field-label {
//   left: 35px !important;
//   top: 10px;
// }

// // #mat-form-field-label-1,
// // #mat-form-field-label-25,
// // #mat-form-field-label-27,
// // #mat-form-field-label-29,
// // #mat-form-field-label-31 {
// //   left: 5rem;
// //   top: 10px;
// // }

// #mat-select-0 {
// }

// #mat-form-field-label-1 {
// }

// #mat-checkbox-1-input {
//   width: 1em;
//   height: 1em;
//   margin-top: 0.25em;
//   vertical-align: top;
//   background-color: #244166;
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: contain;
//   border: transparent;
//   -webkit-appearance: none;
//   -moz-appearance: none;
//   appearance: none;
//   -webkit-print-color-adjust: exact;
//   color-adjust: exact;
//   print-color-adjust: exact;
// }
// }
