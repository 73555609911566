//
// tables.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

.table thead th {
  background-color: $table-head-bg;
  text-transform: uppercase;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  letter-spacing: 0.08em;
  color: $table-head-color;
}

.table thead th,
tbody th,
tbody td {
  vertical-align: middle;
}

.table td,
.table th {
  border-top: $table-border-width solid var(--#{$prefix}table-border-color);
  border-bottom: 0;
}

.table > :not(:first-child) {
  border-top: 0;
}

// Sizing

.table-sm {
  font-size: $font-size-sm;
}

.table-sm thead th {
  font-size: $font-size-xs;
}

//
// Theme =====================================
//

// No wrap
//
// Prevents table content from wrapping to the next line

.table-nowrap {
  th,
  td {
    white-space: nowrap;
  }
}

// Sort
//
// Adds sorting icons

.table [data-sort] {
  white-space: nowrap;

  &::after {
    content: str-replace(
      url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='#{$gray-600}'/></svg>"),
      '#',
      '%23'
    );
    margin-left: 0.25rem;
  }
}

// Links

.table a[class*='text-reset']:hover {
  color: var(--#{$prefix}link-color) !important;
}
