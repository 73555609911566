//
// user.scss
// Use this to write your custom SCSS
//

.tooltip-inner {
  max-width: 400px !important;
}

.dropdown-toggle::after {
  margin-left: 0.01rem !important;
}

.light-link {
  border-bottom: 1px dotted;
  border-bottom-color: rgba(44, 123, 229, 0.9);
}

.thanks-wrap {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateY(-25%) translateX(-50%);
}

.thanks-animation {
  position: relative;
}

.thanks-animation svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-25%) translateX(-50%);
  transform-origin: center;
}

/* hurray animation */

.hurray {
  z-index: 3;
}

.hurray circle {
  animation: circle-hurray 1s forwards;

  transform-origin: center;
  opacity: 0;
}

@keyframes circle-hurray {
  0% {
    opacity: 1;
    stroke-width: 40;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    stroke-width: 2;
    transform: scale(1);
  }
}

.hurray path {
  animation: path-hurray 2s forwards;
  transform-origin: center;
  stroke-dasharray: 1, 40;
  stroke-dashoffset: 0;

  opacity: 0;
}

@keyframes path-hurray {
  0%,
  12% {
    opacity: 0;
  }
  12.5% {
    stroke-dasharray: 15, 40;
    stroke-dashoffset: -40;
    opacity: 1;
    stroke-width: 3;
    transform: rotate(0);
  }
  50%,
  100% {
    stroke-dasharray: 1, 40;
    stroke-dashoffset: 2;
    stroke-width: 0;
    transform: rotate(0);
  }
}

.hurray .check {
  animation: check 2s forwards;
  animation-delay: 1s;
  stroke-dasharray: 1, 40;
  stroke-dashoffset: 0;
}

@keyframes check {
  0%,
  12% {
    opacity: 0;
  }
  12.5% {
    stroke-dasharray: 40, 40;
    stroke-dashoffset: 40;
    opacity: 1;
  }
  50%,
  100% {
    stroke-dasharray: 40, 40;
    stroke-dashoffset: 0;
    opacity: 1;
  }
}

.picklist {
  input[type="radio"] + span.radio-selected {
    display: none;
  }

  input[type="radio"]:checked + span.radio-selected {
    display: flex;
  }

  input[type="radio"]:checked + span.radio-selected + label {
    display: none !important;
  }
}

input.hidden-form-control {
  display: none;
}

input[type="radio"]:checked + label {
  background-color: #152e4d;
  opacity: 1;
  // border:1px solid $primary;
  border: 1px solid $green !important;
}
